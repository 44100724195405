import { API_ENDPOINT } from "../constants";
import { useCallback } from "react";

export interface IUseDisplayNow {
  subscriptionId: string;
  subscriptionConfigurationToken: string;
}

export const useDisplayNow = () => {
  const mutation = useCallback(async (input: IUseDisplayNow) => {
    const response = await fetch(
      `${API_ENDPOINT}/api/subscriptions/${input.subscriptionId}/display-now`,
      {
        method: "POST",
        headers: {
          "x-vestaboard-subscription-configuration-token":
            input.subscriptionConfigurationToken,
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );

    if (!response.ok) {
      throw new Error(await response.json()?.then((e) => e.message));
    }

    return response.json();
  }, []);

  return [mutation];
};
