import * as Sentry from "@sentry/react";

import { App } from "./App";
import React from "react";
import ReactDOM from "react-dom";

Sentry.init({
  dsn: "https://6e7eb76a8f344b3983a6644d4e4e1562@o4505585861591040.ingest.sentry.io/4505596733554688",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  environment: process?.env?.REACT_APP_ENVIRONMENT || "production",
});

ReactDOM.render(<App />, document.getElementById("root"));
